@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
/*@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700&display=swap');*/
/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");*/

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

#root {
  white-space: pre-line;
}

li {
  display: list-item;
  list-style-position: inside;
}
